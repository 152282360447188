import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Research Activity`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Hi ! In this Section you can browse the corresponding Project`}</p>
    </blockquote>
    <ProjectCard title="React Things " link="./react-things" bg="linear-gradient(to right, #c0392b 0%, #8e44ad 100%)" image="1.jpg" mdxType="ProjectCard">
 
    </ProjectCard>
    <ProjectCard title="Back-end Section" link="./back-end" bg="linear-gradient(to right, #c0392b 0%, #8e44ad 100%)" image="2.jpg" mdxType="ProjectCard">
 
    </ProjectCard>
    <ProjectCard title="Artificial Intelegence" link="./artificial-intelegence" bg="linear-gradient(to right, #acb6e5 0%, #86fde8 100%)" image="3.jpg" mdxType="ProjectCard">
  
    </ProjectCard>
    <hr></hr>
    <blockquote>
      <p parentName="blockquote">{`Go Back to `}<a parentName="p" {...{
          "href": "./"
        }}>{`Homepage`}</a></p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      